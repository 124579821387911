import { useBranch } from "../../moo-branch-provider";
import { getDateString } from "../../moo-shared";
import { useStore } from "./useStore";

export function useWhere() {
	const { value: branch } = useBranch();
	const { query } = useStore();

	const where: Record<string, any> = {
		voucher: {
			branch_id: {
				_eq: branch,
			},
			status: {
				_eq: query.status,
			},
		},
	};

	if (query.from_date !== null && query.to_date !== null && query.from_date.getTime() === query.to_date.getTime()) {
		if (!where.voucher.voucher_date) {
			where.voucher.voucher_date = {};
		}
		where.voucher.voucher_date._eq = getDateString(query.from_date);
	} else {
		if (query.from_date !== null) {
			where.voucher.voucher_date = {
				_gte: getDateString(query.from_date),
			};
		}

		if (query.to_date !== null) {
			if (!where.voucher.voucher_date) {
				where.voucher.voucher_date = {};
			}
			where.voucher.voucher_date._lte = getDateString(query.to_date);
		}
	}

	if (query.account_type !== null) {
		where.account_type = {
			id_number: {
				_eq: query.account_type,
			},
		};
	}

	if (query.manufacturer_type !== null) {
		where.manufacturer_type = {
			_eq: query.manufacturer_type,
		};
	}

	if (query.manufacturer_id !== null) {
		where.manufacturer_id = {
			_eq: query.manufacturer_id,
		};
	}

	if (query.search !== null && query.search.trim() !== "") {
		const _similar = `%(${query.search.trim().split(" ").join("|")})%`;
		where._or = [
			{
				voucher_id: {
					_similar,
				},
			},
			{
				link_id: {
					_similar,
				},
			},
			{
				account_type: {
					id_number: {
						_similar,
					},
				},
			},
			{
				account_type: {
					name: {
						_similar,
					},
				},
			},
			{
				summary: {
					_similar,
				},
			},
			{
				manufacturer_domestic: {
					name: {
						_similar,
					},
				},
			},
			{
				manufacturer_foreign: {
					name: {
						_similar,
					},
				},
			},
		];
	}

	if (query.search !== null && query.search.trim() !== "") {
		const _similar = `%(${query.search.trim().split(" ").join("|")})%`;
		where._or = [
			{
				voucher_id: {
					_similar,
				},
			},
			{
				link_id: {
					_similar,
				},
			},
			{
				account_type: {
					id_number: {
						_similar,
					},
				},
			},
			{
				account_type: {
					name: {
						_similar,
					},
				},
			},
			{
				summary: {
					_similar,
				},
			},
			{
				manufacturer_domestic: {
					name: {
						_similar,
					},
				},
			},
			{
				manufacturer_foreign: {
					name: {
						_similar,
					},
				},
			},
		];
	}

	return where;
}
