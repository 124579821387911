export enum Permission {
	CustomerListRead = "CustomerListRead",
	CustomerRead = "CustomerRead",
	CustomerAdd = "CustomerAdd",
	CustomerUpdate = "CustomerUpdate",
	CustomerTypeWrite = "CustomerTypeWrite",

	ManufacturerDomesticListRead = "ManufacturerDomesticListRead",
	ManufacturerDomesticRead = "ManufacturerDomesticRead",
	ManufacturerDomesticAdd = "ManufacturerDomesticAdd",
	ManufacturerDomesticUpdate = "ManufacturerDomesticUpdate",
	ManufacturerDomesticTypeWrite = "ManufacturerDomesticTypeWrite",

	ManufacturerForeignListRead = "ManufacturerForeignListRead",
	ManufacturerForeignRead = "ManufacturerForeignRead",
	ManufacturerForeignAdd = "ManufacturerForeignAdd",
	ManufacturerForeignUpdate = "ManufacturerForeignUpdate",
	ManufacturerForeignTypeWrite = "ManufacturerForeignTypeWrite",

	ManufacturerLogisticsListRead = "ManufacturerLogisticsListRead",
	ManufacturerLogisticsRead = "ManufacturerLogisticsRead",
	ManufacturerLogisticsAdd = "ManufacturerLogisticsAdd",
	ManufacturerLogisticsUpdate = "ManufacturerLogisticsUpdate",

	ItemSpecListRead = "ItemSpecListRead",
	ItemSpecRead = "ItemSpecRead",
	ItemSpecAdd = "ItemSpecAdd",
	ItemSpecUpdate = "ItemSpecUpdate",
	ItemSpecPurchasePriceRead = "ItemSpecPurchasePriceRead",
	ItemSpecPurchasePriceAdd = "ItemSpecPurchasePriceAdd",
	ItemSpecPurchasePriceUpdate = "ItemSpecPurchasePriceUpdate",
	ItemSpecCostRead = "ItemSpecCostRead",
	ItemSpecCostAdd = "ItemSpecCostAdd",
	ItemSpecCostUpdate = "ItemSpecCostUpdate",
	ItemSpecPriceRead = "ItemSpecPriceRead",
	ItemSpecPriceAdd = "ItemSpecPriceAdd",
	ItemSpecPriceUpdate = "ItemSpecPriceUpdate",

	ItemCustomListRead = "ItemCustomListRead",
	ItemCustomRead = "ItemCustomRead",
	ItemCustomAdd = "ItemCustomAdd",
	ItemCustomUpdate = "ItemCustomUpdate",
	ItemCustomPurchasePriceRead = "ItemCustomPurchasePriceRead",
	ItemCustomPurchasePriceAdd = "ItemCustomPurchasePriceAdd",
	ItemCustomPurchasePriceUpdate = "ItemCustomPurchasePriceUpdate",
	ItemCustomCostRead = "ItemCustomCostRead",
	ItemCustomCostAdd = "ItemCustomCostAdd",
	ItemCustomCostUpdate = "ItemCustomCostUpdate",
	ItemCustomPriceRead = "ItemCustomPriceRead",
	ItemCustomPriceAdd = "ItemCustomPriceAdd",
	ItemCustomPriceUpdate = "ItemCustomPriceUpdate",

	ItemBundleListRead = "ItemBundleListRead",
	ItemBundleAdd = "ItemBundleAdd",
	ItemBundleRead = "ItemBundleRead",
	ItemBundleUpdate = "ItemBundleUpdate",

	QuotationTypeWrite = "QuotationTypeWrite",
	QuotationListRead = "QuotationListRead",
	QuotationRead = "QuotationRead",
	QuotationAdd = "QuotationAdd",
	QuotationUpdate = "QuotationUpdate",
	QuotationStateWrite = "QuotationStateWrite",
	QuotationStateManagerWrite = "QuotationStateManagerWrite",
	QuotationStateAccountantWrite = "QuotationStateAccountantWrite",

	OrderListRead = "OrderListRead",
	OrderRead = "OrderRead",
	OrderAdd = "OrderAdd",
	OrderUpdate = "OrderUpdate",
	OrderStateWrite = "OrderStateWrite",
	OrderStateManagerWrite = "OrderStateManagerWrite",
	OrderStateAccountantWrite = "OrderStateAccountantWrite",
	OrderStateWarehouseWrite = "OrderStateWarehouseWrite",

	OrderGoodsListRead = "OrderGoodsListRead",
	OrderGoodsRead = "OrderGoodsRead",
	OrderGoodsAdd = "OrderGoodsAdd",
	OrderGoodsUpdate = "OrderGoodsUpdate",

	ReturnOrderListRead = "ReturnOrderListRead",
	ReturnOrderRead = "ReturnOrderRead",
	ReturnOrderUpdate = "ReturnOrderUpdate",
	ReturnOrderAdd = "ReturnOrderAdd",
	ReturnOrderStateWrite = "ReturnOrderStateWrite",

	ReturnGoodsListRead = "ReturnGoodsListRead",
	ReturnGoodsRead = "ReturnGoodsRead",
	ReturnGoodsAdd = "ReturnGoodsAdd",
	ReturnGoodsUpdate = "ReturnGoodsUpdate",

	PurchaseOrderListRead = "PurchaseOrderListRead",
	PurchaseOrderRead = "PurchaseOrderRead",
	PurchaseOrderAdd = "PurchaseOrderAdd",
	PurchaseOrderUpdate = "PurchaseOrderUpdate",
	PurchaseOrderStateWrite = "PurchaseOrderStateWrite",
	PurchaseOrderStateManagerWrite = "PurchaseOrderStateManagerWrite",
	PurchaseOrderStateAccountantWrite = "PurchaseOrderStateAccountantWrite",

	RequestBuyListRead = "RequestBuyListRead",
	RequestBuyRead = "RequestBuyRead",
	RequestBuyAdd = "RequestBuyAdd",
	RequestBuyUpdate = "RequestBuyUpdate",
	RequestBuyStatePurchaseWrite = "RequestBuyStatePurchaseWrite",

	SvcListRead = "SvcListRead",
	SvcRead = "SvcRead",
	SvcAdd = "SvcAdd",
	SvcUpdate = "SvcUpdate",
	SvcStateAccountantWrite = "SvcStateAccountantWrite",

	StockTransferListRead = "StockTransferListRead",
	StockTransferRead = "StockTransferRead",
	StockTransferAdd = "StockTransferAdd",
	StockTransferUpdate = "StockTransferUpdate",
	StockTransferStateWrite = "StockTransferStateWrite",

	DefectiveProductListRead = "DefectiveProductListRead",
	DefectiveProductRead = "DefectiveProductRead",
	DefectiveProductAdd = "DefectiveProductAdd",
	DefectiveProductUpdate = "DefectiveProductUpdate",
	DefectiveProductStateWrite = "DefectiveProductStateWrite",

	RoleListRead = "RoleListRead",
	RoleRead = "RoleRead",
	RoleAdd = "RoleAdd",
	RoleUpdate = "RoleUpdate",

	ImportGoodsListRead = "ImportGoodsListRead",
	ImportGoodsRead = "ImportGoodsRead",
	ImportGoodsAdd = "ImportGoodsAdd",
	ImportGoodsUpdate = "ImportGoodsUpdate",
	ImportGoodsStateWrite = "ImportGoodsStateWrite",
	ImportGoodsStateAccountantWrite = "ImportGoodsStateAccountantWrite",

	ExportGoodsListRead = "ExportGoodsListRead",
	ExportGoodsRead = "ExportGoodsRead",
	ExportGoodsAdd = "ExportGoodsAdd",
	ExportGoodsUpdate = "ExportGoodsUpdate",
	ExportGoodsStateWrite = "ExportGoodsStateWrite",

	VoucherListRead = "VoucherListRead",
	VoucherRead = "VoucherRead",
	VoucherAdd = "VoucherAdd",
	VoucherUpdate = "VoucherUpdate",

	AllowanceListRead = "AllowanceListRead",
	AllowanceRead = "AllowanceRead",
	AllowanceAdd = "AllowanceAdd",
	AllowanceUpdate = "AllowanceUpdate",
	AllowanceStateManagerWrite = "AllowanceStateManagerWrite",
	AllowanceStateAccountantWrite = "AllowanceStateAccountantWrite",
	AllowanceStateRequesterWrite = "AllowanceStateRequesterWrite",

	SalaryRead = "SalaryRead",

	PerformanceRead = "PerformanceRead",

	EmployeeTypeWrite = "EmployeeTypeWrite",
	EmployeeListRead = "EmployeeListRead",
	EmployeeRead = "EmployeeRead",
	EmployeeAdd = "EmployeeAdd",
	EmployeeUpdate = "EmployeeUpdate",
	EmployeeSalaryRead = "EmployeeSalaryRead",
	EmployeeSalaryWrite = "EmployeeSalaryWrite",
	EmployeeAssignRole = "EmployeeAssignRole",

	ItemCategoryWrite = "ItemCategoryWrite",
	PayeeWrite = "PayeeWrite",
	AccountSubjectWrite = "AccountSubjectWrite",
	WarehouseWrite = "WarehouseWrite",
}
