import { Query } from "./@types";
import { PdfDownloadButton } from "./pdf/PdfDownloadButton";
import { AccountTypeSelect } from "./selects/AccountTypeSelect";
import { DateRangeSelect } from "./selects/DateRangeSelect";
import { ManufacturerIdSelect } from "./selects/ManufacturerIdSelect";
import { ManufacturerTypeSelect } from "./selects/ManufacturerTypeSelect";
import { StatusSelect } from "./selects/StatusSelect";

type Props = {
	query: Query;
	setQuery: (query: Partial<Query>) => void;
};

export function FilterNode({ query, setQuery }: Props) {
	return (
		<>
			<AccountTypeSelect query={query} setQuery={setQuery} />
			<ManufacturerTypeSelect query={query} setQuery={setQuery} />
			<ManufacturerIdSelect query={query} setQuery={setQuery} />
			<DateRangeSelect query={query} setQuery={setQuery} />
			<StatusSelect query={query} setQuery={setQuery} />
			<PdfDownloadButton />
		</>
	);
}
